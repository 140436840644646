<template>
    <div>
      <div class="header">
        <button v-if="!isMobile" @click="signOut" class="sign-out">Sign Out</button>
        <button v-if="isMobile" @click="signOut" class="sign-out-mobile">Sign Out</button>
      </div>
      <div class="welcome-container">
        <h1>Welcome to Body Cunt, {{ firstName }}!</h1>
        <p>Body Cunt is a luxury apparel company based in Los Angeles, California. Born out of a need to "free women's clothing", we design 100% sustainable clothing for women that are not bounded by conventional ideas.</p>
        <router-link to="/dashboard" class="dashboard-link">Go to Dashboard</router-link>
      </div>
    </div>
  </template>
  
  <script>
  import { auth, db } from "@/firebase";
  import { signOut } from "firebase/auth";
  import { doc, getDoc } from "firebase/firestore";
  
  export default {
    name: "Welcome",
    data() {
      return {
        firstName: '',
        isMobile: false
      };
    },
    async created() {
      this.isMobile = window.innerWidth <= 480;
      const storedFirstName = localStorage.getItem('firstName');
      if (storedFirstName) {
        this.firstName = storedFirstName;
      } else {
        await this.fetchUserData();
      }
    },
    methods: {
      async fetchUserData() {
        const user = auth.currentUser;
        if (user) {
          const userId = user.uid;
          try {
            const userRef = doc(db, 'users', userId);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
              this.firstName = docSnap.data().firstName;
              localStorage.setItem('firstName', this.firstName); // Store first name in local storage
            } else {
              console.log("No such document!");
            }
          } catch (error) {
            console.error("Error getting document:", error);
          }
        }
      },
      signOut() {
        signOut(auth).then(() => {
          localStorage.removeItem('firstName'); // Remove first name from local storage on sign out
          this.$router.push('/');
        }).catch((error) => {
          console.error("Error signing out: ", error);
        });
      },
      updateIsMobile() {
        this.isMobile = window.innerWidth <= 480;
      }
    },
    mounted() {
      window.addEventListener('resize', this.updateIsMobile);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateIsMobile);
    }
  };
  </script>
  
  <style scoped>
  .welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: white;
    background-color: black;
    text-align: center;
    padding: 20px;
  }
  
  .dashboard-link {
    margin-top: 20px;
    padding: 10px 20px;
    color: red;
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: none;
  }
  
  .dashboard-link:hover {
    text-decoration: underline;
  }
  
  p {
    margin-top: 20px;
    padding: 0 20px;
  }
  
  .sign-out {
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .sign-out:hover {
    background-color: darkred;
  }
  
  .sign-out-mobile {
    color: red;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .sign-out-mobile:hover {
    text-decoration: underline;
  }
  
  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
  </style>
  