<template>
    <div class="product-view">
      <img :src="product.imageURL" :alt="product.title" class="product-image" />
      <h2>{{ product.title }}</h2>
      <p>{{ product.description }}</p>
      <p>${{ product.price }}</p>
      <button @click="goToPayment">Buy</button>
    </div>
  </template>
  
  <script>
  export default {
    name: "ProductView",
    props: {
      product: {
        type: Object,
        required: true,
      },
    },
    methods: {
      goToPayment() {
        this.$router.push({ name: 'PaymentOptions', query: { price: this.product.price } });
      }
    }
  };
  </script>
  
  <style scoped>
  .product-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    height: 100vh;
    text-align: center;
  }
  
  .product-image {
    width: 200px;
    border-radius: 15px;
  }
  
  button {
    padding: 10px 20px;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1em;
  }
  
  button:hover {
    background-color: darkred;
  }
  </style>
  