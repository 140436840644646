// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  // Your Firebase configuration object
  apiKey: "AIzaSyAATazBYOzfcIQqpQCwo7cDmwh-KJrMC2w",
  authDomain: "bodycunt.firebaseapp.com",
  projectId: "bodycunt",
  storageBucket: "bodycunt.appspot.com",
  messagingSenderId: "455145851754",
  appId: "1:455145851754:web:20e130e0446ac3a9f63313"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };

