import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import SignIn from '../components/SignIn.vue';
import SignUp from '../components/SignUp.vue';
import Welcome from '../components/Welcome.vue';
import Dashboard from '../components/Dashboard.vue';
import ProductView from '../components/ProductView.vue';
import PaymentOptions from '../components/PaymentOptions.vue';
import OrderConfirmation from '../components/OrderConfirmation.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    props: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
  },
  {
    path: '/product',
    name: 'ProductView',
    component: ProductView,
    props: route => ({ product: JSON.parse(decodeURIComponent(route.query.product)) }),
  },
  {
    path: '/payment',
    name: 'PaymentOptions',
    component: PaymentOptions,
    props: route => ({ price: route.query.price }),
  },
  {
    path: '/order-confirmation',
    name: 'OrderConfirmation',
    component: OrderConfirmation,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
