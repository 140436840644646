<template>
  <div class="signup-container">
    <h2>Sign Up</h2>
    <form @submit.prevent="signUp">
      <label for="firstName">First Name:</label>
      <input type="text" v-model="firstName" required />
      <label for="email">Email:</label>
      <input type="email" v-model="email" required />
      <label for="password">Password:</label>
      <input type="password" v-model="password" required />
      <button type="submit">Sign Up</button>
    </form>
    <router-link to="/signin" class="signin-link">Already have an account? Sign in</router-link>
  </div>
</template>

<script>
import { auth, db } from '@/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';

export default {
  name: 'SignUp',
  data() {
    return {
      firstName: '',
      email: '',
      password: ''
    };
  },
  methods: {
    async signUp() {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        // Add user info to Firestore
        await setDoc(doc(db, 'users', user.uid), {
          firstName: this.firstName,
          email: this.email
        });

        this.$router.push({ name: 'Welcome', params: { userId: user.uid } });
      } catch (error) {
        console.error('Error signing up:', error);
      }
    }
  }
};
</script>

<style scoped>
/* Your existing CSS */
.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  background-color: black;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 300px;
}

label {
  margin-top: 10px;
  text-align: left;
}

input {
  padding: 10px;
  margin-top: 5px;
}

button {
  margin-top: 20px;
  padding: 10px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: darkred;
}

.signin-link {
  margin-top: 20px;
  color: red;
  text-decoration: none;
}

.signin-link:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  form {
    width: 90%;
  }
}
</style>
