<template>
  <div>
    <div class="container">
      <img src="@/assets/myphoto.png" alt="Your Image" class="image" />
      <div class="content-container">
        <div class="content">
          Body Cunt
        </div>
        <div class="get-started-container">
          <router-link to="/signin" class="get-started">Get started</router-link>
          <div class="red-line"></div>
        </div>
      </div>
    </div>
    <footer class="footer">
      2024
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style scoped>
/* Apply global font style */
* {
  font-family: 'Azeret Mono', monospace;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; /* Adds space between the content and the link */
}

.image {
  border-radius: 15px; /* Adjust the border-radius for rounded corners */
  width: 200px; /* Set your desired width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  margin-right: 20px; /* Adjust the spacing between the image and the text */
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align content */
}

.content {
  color: white;
  font-size: 4em;
  font-weight: bold;
  text-align: center; /* Center align text */
}

.get-started-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align both text and line */
  margin-top: 10px;
}

.get-started {
  font-size: 1.5em;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
}

.get-started:hover {
  color: #c5c5c5;
}

.red-line {
  width: 100px; /* Adjust width as needed */
  height: 2px;
  background-color: red;
  margin-top: 5px; /* Adds spacing below the button */
}

.footer {
  position: absolute;
  bottom: 5vh; /* 5% of the viewport height from the bottom */
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1em;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    text-align: center;
  }

  .image {
    margin-right: 0;
    margin-bottom: 20px;
    width: 150px;
  }

  .content {
    font-size: 3em;
  }

  .get-started {
    font-size: 1.2em;
  }

  .red-line {
    width: 80px;
  }
}

@media (max-width: 480px) {
  .container {
    flex-direction: column;
    text-align: center;
  }

  .image {
    margin-right: 0;
    margin-bottom: 20px;
    width: 120px;
  }

  .content {
    font-size: 2em;
  }

  .get-started {
    font-size: 1em;
  }

  .red-line {
    width: 60px;
  }

  .footer {
    font-size: 0.8em;
  }
}
</style>
