<template>
    <div class="payment-container">
      <h1>How would you like to pay for your order?</h1>
      <h2>\${{ price }}</h2>
      <div class="payment-method" @click="selectMethod('card')">
        Debit/Credit Card
        <div v-if="selectedMethod === 'card'" class="underline"></div>
      </div>
      <div class="payment-method" @click="selectMethod('bank')">
        Bank Transfer
        <div v-if="selectedMethod === 'bank'" class="underline"></div>
      </div>
      <button @click="proceedPayment">Proceed</button>
    </div>
  </template>
  
  <script>
  import { addDoc, collection } from "firebase/firestore";
  import { db } from "@/firebase";
  
  export default {
    name: 'PaymentOptions',
    props: {
      price: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        selectedMethod: 'card',
        email: 'customer@example.com',
        publicKey: 'pk_test_30543bec78a7d81ca2f591e99bce6929de0a0586', // Replace with your Paystack public key
      };
    },
    computed: {
      amount() {
        return this.price * 100; // Convert to kobo
      }
    },
    methods: {
      selectMethod(method) {
        this.selectedMethod = method;
      },
      proceedPayment() {
        if (this.selectedMethod === 'card') {
          this.payWithPaystack();
        } else {
          alert('Bank transfer is not yet implemented.');
        }
      },
      payWithPaystack() {
        if (typeof window.PaystackPop !== 'undefined') {
          const handler = window.PaystackPop.setup({
            key: this.publicKey,
            email: this.email,
            amount: this.amount,
            currency: 'NGN',
            callback: (response) => {
              this.saveOrder(response);
            },
            onClose: () => {
              alert('Transaction was not completed, window closed.');
            },
          });
          handler.openIframe();
        } else {
          alert('Paystack script not loaded. Please try again.');
        }
      },
      async saveOrder(response) {
        const order = {
          email: this.email,
          amount: this.price,
          transactionId: response.reference,
          status: 'success',
        };
        try {
          await addDoc(collection(db, "orders"), order);
          console.log('Order saved successfully');
          this.$router.push({ name: 'OrderConfirmation' });
        } catch (error) {
          console.error('Error saving order:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    height: 100vh;
    text-align: center;
  }
  
  .payment-method {
    cursor: pointer;
    margin: 10px 0;
  }
  
  .underline {
    height: 2px;
    background-color: red;
    width: 100%;
    margin-top: 5px;
  }
  
  button {
    padding: 10px 20px;
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
    font-size: 1em;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  button:hover {
    background-color: grey;
    color: white;
  }
  </style>
  