import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Vue2TouchEvents from 'vue2-touch-events';
import VuePaystack from 'vue-paystack';

// Set the feature flag globally for Vue
if (typeof __VUE_PROD_HYDRATION_MISMATCH_DETAILS__ === 'undefined') {
  globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;
}

const app = createApp(App);
app.use(router);
app.use(Vue2TouchEvents);
app.use(VuePaystack);
app.mount('#app');
