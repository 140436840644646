<template>
    <div class="dashboard-container">
      <div class="carousel">
        <div class="arrow left" @click="prevProduct">&#10094;</div>
        <div class="carousel-inner">
          <div
            v-for="(product, index) in displayedProducts"
            :key="product.id"
            :class="['carousel-item', { active: index === 1 }]"
          >
            <img :src="product.imageURL" :alt="product.title" class="product-image" />
          </div>
        </div>
        <div class="arrow right" @click="nextProduct">&#10095;</div>
      </div>
      <div class="product-details">
        <h2>{{ currentProduct.title }}</h2>
        <p>{{ currentProduct.description }}</p>
        <p>${{ currentProduct.price }}</p>
        <button @click="viewProduct(currentProduct)">Buy</button>
      </div>
    </div>
  </template>
  
  <script>
  import { db } from "@/firebase";
  import { collection, getDocs } from "firebase/firestore";
  
  export default {
    name: "Dashboard",
    data() {
      return {
        products: [],
        currentIndex: 0,
      };
    },
    computed: {
      displayedProducts() {
        if (this.products.length === 0) return [];
        const prevIndex = (this.currentIndex - 1 + this.products.length) % this.products.length;
        const nextIndex = (this.currentIndex + 1) % this.products.length;
        return [this.products[prevIndex], this.products[this.currentIndex], this.products[nextIndex]];
      },
      currentProduct() {
        return this.products[this.currentIndex] || {};
      },
    },
    methods: {
      async fetchProducts() {
        const querySnapshot = await getDocs(collection(db, "products"));
        this.products = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      },
      nextProduct() {
        this.currentIndex = (this.currentIndex + 1) % this.products.length;
      },
      prevProduct() {
        this.currentIndex = (this.currentIndex - 1 + this.products.length) % this.products.length;
      },
      viewProduct(product) {
        console.log("Navigating to product view with product:", product);
        this.$router.push({
          name: 'ProductView',
          query: { product: encodeURIComponent(JSON.stringify(product)) },
        });
      }
    },
    created() {
      this.fetchProducts();
    },
  };
  </script>
  
  <style scoped>
  .dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
  }
  
  .carousel-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
  }
  
  .carousel-item {
    flex: 0 0 auto;
    width: 200px;
    margin: 0 10px;
    transition: transform 1s ease, opacity 1s ease;
    opacity: 0.5;
    transform: scale(0.8);
  }
  
  .carousel-item.active {
    opacity: 1;
    transform: scale(1);
  }
  
  .product-image {
    width: 100%;
    border-radius: 15px;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2em;
    color: white;
    cursor: pointer;
    user-select: none;
    z-index: 1;
  }
  
  .arrow.left {
    left: 10px;
  }
  
  .arrow.right {
    right: 10px;
  }
  
  .product-details {
    text-align: center;
    margin-top: 20px;
  }
  
  .product-details h2 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .product-details p {
    font-size: 1.2em;
  }
  
  button {
    padding: 10px 20px;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1em;
  }
  
  button:hover {
    background-color: darkred;
  }
  </style>
  