<template>
  <div class="signin-container">
    <h2>Sign In</h2>
    <form @submit.prevent="signIn">
      <label for="email">Email:</label>
      <input type="email" v-model="email" required />
      <label for="password">Password:</label>
      <input type="password" v-model="password" required />
      <button type="submit">Sign In</button>
    </form>
    <router-link to="/signup" class="signup-link">Don't have an account? Sign up</router-link>
  </div>
</template>

<script>
import { auth } from '@/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

export default {
  name: 'SignIn',
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async signIn() {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;
        this.$router.push({ name: 'Dashboard', params: { userId: user.uid } });
      } catch (error) {
        console.error('Error signing in:', error);
      }
    }
  }
};
</script>

<style scoped>
/* Your existing CSS */
.signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  background-color: black;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 300px;
}

label {
  margin-top: 10px;
  text-align: left;
}

input {
  padding: 10px;
  margin-top: 5px;
}

button {
  margin-top: 20px;
  padding: 10px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: darkred;
}

.signup-link {
  margin-top: 20px;
  color: red;
  text-decoration: none;
}

.signup-link:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  form {
    width: 90%;
  }
}
</style>
