<template>
    <div class="confirmation-container">
      <h1>Order Confirmed</h1>
      <p>Your order has been confirmed and it is being processed. We will send you an update via email. Thank you for your purchase.</p>
      <button @click="shopAgain">Shop Again</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OrderConfirmation',
    methods: {
      shopAgain() {
        this.$router.push({ name: 'Dashboard' });
      }
    }
  };
  </script>
  
  <style scoped>
  .confirmation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    height: 100vh;
    text-align: center;
  }
  
  button {
    padding: 10px 20px;
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
    font-size: 1em;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  button:hover {
    background-color: grey;
    color: white;
  }
  </style>
  